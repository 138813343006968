<template>
  <div v-if="loaded && slide" class="resting-slide-container">
    <slide-heading
      :html-heading="slide.heading"
    />

    <div v-if="slide.image_urls.length && slide.description">
      <slide-image-and-description :slide="slide" />
    </div>

    <div v-else-if="slide.description">
      <div class="description">
        <!-- eslint-disable vue/no-v-html -->
        <div v-html="slideDescription" />
        <!--eslint-enable-->
      </div>
    </div>

    <div v-else-if="slide.image_urls.length">
      <div class="image-vert">
        <img :src="imageUrl" :alt="slide.image_alt_text">
      </div>
    </div>
  </div>

  <div v-else>
    <div class="d-flex justify-content-center mt-2 mb-2">
      <b-spinner variant="primary" large />
    </div>
  </div>
</template>

<script>
import * as DOMPurify from 'dompurify'

export default {
    name: 'RestingSlide',
    components: {
      SlideImageAndDescription: () => import('@/components/StagelineV2/slides/SlideImageAndDescription'),
      SlideHeading:             () => import('@/components/StagelineV2/shared/SlideHeading'),
    },
    props: {
      slide: null,
    },
    data() {
      return {
        loaded: false,
      }
    },
    computed: {
      imageUrl() {
        return this.slide.image_urls[0]
      },
      layoutFormat() {
        return this.slide.layout_format
      },
      slideDescription() {
        return DOMPurify.sanitize(this.slide.description)
      },
    },
    mounted() {
      this.loaded = true
    },
  }
</script>
<style scoped lang="scss">
  .resting-slide-container {
    .image-vert {
      text-align: center;
      height:400px;
      img {
        height: 100%;
      }
    }
  }

  @media only screen and (max-width: 660px) {
    .resting-slide-container {
      width: 100%;
      .image-vert {
        height: 100%;
        img {
          width: 100%;
        }
      }
    }
  }
</style>
